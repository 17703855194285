console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);

jQuery( document ).ready(function( $ ) {


$("#js-eventClick-show-fragment-text__c-the-lice").click(function(){
  this.remove();
  $('#js-layer-fragment-text__c-the-lice').removeClass('dn').addClass('e-fade-in');
});
$("#js-eventClick-show__c-address").click(function(){
  this.remove();
  $('#js-layer__c-address').removeClass('dn').addClass('e-fade-in');
});


$('#js-clickEvent--c-menu__btn-mobile').click(function(){


  $('#js-clickEvent--c-menu__btn-mobile').toggleClass('is-active');
  $('#body').toggleClass('is-active-menu-mobile');
});

// anchor link
 // Add class menu reference archos url load page
 if (window.location.hash!=""){
  var menu__top = document.getElementById("menu-top");
  menu__top.className = "";
  var archos = window.location.hash.substr(1);
  menu__top.classList.add(archos);
}



// Resize window for web
window.addEventListener("scroll", resizeFunction);
window.addEventListener("resize", resizeFunction);
window.addEventListener("load", resizeFunction);


function resizeFunction() {
  var bodyHeight = document.documentElement.clientHeight;
  //console.log(window.pageYOffset);
}


window.addEventListener("scroll",recorrido);
function recorrido(){
      // sectors = ["empresa","distribucio","elaboracio-propia","hosteleria","contacte"];
      sectors = ["0","1","2","3","4"];

      pixelTop = [];for (var i = 0; i < sectors.length; i++) {

        d = document.getElementById(sectors[i]);
        
        pixelTop[i] = d.offsetTop - window.pageYOffset-document.getElementById("header").offsetHeight;

        if (pixelTop[i]<= document.getElementById("header").offsetHeight) {


          // console.log(d.id + ' : ' + d.offsetTop +  ' : ' + pixelTop[i]);


          var menu__top = document.getElementById("menu-top");



          menu__top.className = "";
          menu__top.classList.add('item-'+sectors[i]);

          // if ( d.id == "4" && pixelTop[i]<-200){
          //   menu__top.className = "";
          //   menu__top.classList.add('item-5');
          // }
        }
      }
    }



// anchor link
// Add smooth scrolling to all links
$("a").on('click', function(event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {

      document.getElementById('body').classList.remove('is-active-menu-mobile');
      document.getElementById('js-clickEvent--c-menu__btn-mobile').classList.remove('is-active');

      $('.header__menu').removeClass('is-active');
      
      // Prevent default anchor click behavior
      // event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      var headerHeight = document.getElementsByTagName("header");

      var offsetHeight = document.getElementById('header').offsetHeight;


      if (headerHeight[0].classList != "fixed"){
        
        var topPage = $(hash).offset().top - offsetHeight;
        console.log(topPage);
      } else {
        var topPage = $(hash).offset().top;
    }

    $('html, body').animate({
      scrollTop: topPage
    }, 800, function(){

        //console.log($(hash).offset().top);
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
        var archos = window.location.hash.substr(1);
        var headerHeight = document.getElementsByTagName("header");
      });
    } // End if


  });


 $( window).scroll(function() {
      if ($(window).scrollTop()> 300 ) {
        $('#header').addClass('is-active');
      } if ($(window).scrollTop()< 120 ) {
        $('#header').removeClass('is-active');
      }
    });

});




